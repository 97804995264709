@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /*
    *
    * This all are the rgb values which are used inside the
    * tailwind config file and custom plugins css file
    *
    */

    /*
    * primary color
    */
    --color-accent: 0, 159, 127;

    /*
    * Hover state color of primary color
    */
    --color-accent-hover: 1, 147, 118;

    /*
    * shade of primary color
    */
    --color-accent-200: 204, 236, 229;

    /*
    * shade of primary color
    */
    --color-accent-300: 153, 217, 204;

    /*
    * shade of primary color
    */
    --color-accent-400: 77, 188, 165;

    /*
    * shade of primary color
    */
    --color-accent-500: 0, 159, 127;

    /*
    * shade of primary color
    */
    --color-accent-600: 0, 143, 114;

    /*
    * shade of primary color
    */
    --color-accent-700: 0, 119, 95;

    /*
    * light color
    */
    --color-light: 255, 255, 255;

    /*
    * dark color
    */
    --color-dark: 0, 0, 0;

    /*
    * Black muted color
    */
    --color-muted-black: 17, 17, 17;

    /*
    * Current color
    */
    --color-current: currentColor;

    /*
    *
    * TEXT colors
    *
    */

    /*
    * text color used for body/base text -> gray-500
    */
    --text-base: 107, 114, 128;

    /*
    * dark base text color  -> gray-600
    */
    --text-base-dark: 75, 85, 99;

    /*
    * muted text color -> gray-400
    */
    --text-muted: 156, 163, 175;

    /*
    * light muted text color - gray-300
    */
    --text-muted-light: 209, 213, 219;

    /*
    * text color used for heading text -> gray-700
    */
    --text-sub-heading: 55, 65, 81;

    /*
    * text color used for heading text -> gray-800
    */
    --text-heading: 31, 41, 55;

    /*
    * text color used for heading text -> gray-900
    */
    --text-bolder: 31, 41, 55;

    /*
    *
    * border colors
    *
    */

    /*
    * border color 50
    */
    --color-border-50: 249, 250, 251;

    /*
    * border color 100
    */
    --color-border-100: 243, 244, 246;

    /*
    * border color 200
    */
    --color-border-200: 229, 231, 235;

    /*
    * border base color which is 300
    */
    --color-border-base: 209, 213, 219;

    /*
    * border color 400
    */
    --color-border-400: 156, 163, 175;

    /*
    *
    * Tailwind default gray color override
    *
    */

    /*
    * gray-50
    */
    --color-gray-50: 249, 250, 251;

    /*
    * gray-100
    */
    --color-gray-100: 243, 244, 246;

    /*
    * gray-200
    */
    --color-gray-200: 229, 231, 235;

    /*
    * gray-300
    */
    --color-gray-300: 209, 213, 219;

    /*
    * gray-400
    */
    --color-gray-400: 156, 163, 175;

    /*
    * gray-500
    */
    --color-gray-500: 107, 114, 128;

    /*
    * gray-600
    */
    --color-gray-600: 75, 85, 99;

    /*
    * gray-700
    */
    --color-gray-700: 55, 65, 81;

    /*
    * gray-800
    */
    --color-gray-800: 31, 41, 55;

    /*
    * gray-900
    */
    --color-gray-900: 31, 41, 55;

    /*
    * orange-50
    */
    --color-orange-50: 255, 247, 237;

    /*
    * orange-100
    */
    --color-orange-100: 255, 237, 213;

    /*
    * orange-200
    */
    --color-orange-200: 254, 215, 170;

    /*
    * orange-300
    */
    --color-orange-300: 253, 186, 116;

    /*
    * orange-400
    */
    --color-orange-400: 251, 146, 60;

    /*
    * orange-500
    */
    --color-orange-500: 249, 115, 22;

    /*
    * orange-600
    */
    --color-orange-600: 234, 88, 12;

    /*
    * orange-700
    */
    --color-orange-700: 194, 65, 12;

    /*
    * orange-800
    */
    --color-orange-800: 154, 52, 18;

    /*
    * orange-900
    */
    --color-orange-900: 124, 45, 18;

    /*
    Pending
    */
    --color-pending: 201, 161, 22;

    /*
    Processing
    */
    --color-processing: 158, 117, 0;

    /*
    Complete
    */
    --color-complete: 0, 161, 127;

    /*
    Canceled
    */
    --color-canceled: 227, 110, 1;

    /*
    Failed
    */
    --color-failed: 238, 43, 0;

    /*
    Out for delivery
    */
    --color-out-for-delivery: 126, 173, 66;
  }

  html {
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    @apply font-body;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-heading;
  }

  a {
    @apply no-underline;
  }
}
@import './custom-plugins.css';
